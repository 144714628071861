.header {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 0;
    border-bottom: 1px solid #eaeaea;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
  }
  
  .header-logo {
    display: flex;
    align-items: center;
  }
  
  .header-logo-link {
    display: inline-block;
  }
  
  .header-logo-img {
    height: 50px;
    width: auto;
  }
  
  .header-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
  }
  
  @media (max-width: 768px) {
    .header-container {
      flex-direction: column;
      text-align: center;
      padding: 0 1rem;
    }
    .header-title {
      margin-top: 1rem;
      font-size: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .header-logo-img {
      height: 40px;
    }
    .header-title {
      font-size: 1rem;
    }
  } 